<!-- 提货券页面 -->
<template>
  <div class="sld_myPoint_wrapper">
    <MemberTitle memberTitle="提货券" style="padding-left:20px"></MemberTitle>
    <div class="pointCon">
      <div class="jifen_top">
        <div class="bindGift" @click="toBindGiftCard">绑定提货券</div>
      </div>

      <div class="content_tit">
        <ul class="tabsTitle">
          <li :class="{ current: indexNum == 0 }" id="all" @click="handleSwitch(0)">待使用</li>
          <li :class="{ current: indexNum == 1 }" id="income" @click="handleSwitch(1)">已使用</li>
          <li :class="{ current: indexNum == 2 }" id="income" @click="handleSwitch(2)">已过期</li>
        </ul>
      </div>
      <!-- 提货券列表 -->
      <div class="voucher_list flex_row_start_start">
        <div class="voucher_item flex_row_between_center" :class="indexNum != 0 ? 'already_item' : ''"  v-for="(item ,index) in pointList.list" :key="index">
          <div class="voucher_item_left flex_column_between_start">
            <div class="title">{{item.giftName}}</div>
            <div class="time">{{item.bindTime}}-{{item.effectiveEnd}}</div>
          </div>
          <div v-if="indexNum == 0" class="voucher_item_right flex_row_center_center" @click="toUse(item)">立即使用</div>
        </div>
      </div>
       <div class="detail_none flex_row_center_center"
            v-if="pointList.list.length==0">
            <img :src="noneUrl" style="width: 100px;margin-bottom: 15px;">
            <p>{{L['暂无数据']}}</p>
        </div>
      <div class="flex_row_end_center sld_pagination">
        <!-- <el-pagination @current-change="handleCurrentChange" v-model:currentPage="pageData.page.current"
          :page-size="pageData.page.pageSize" layout="prev, pager, next, jumper" :total="pageData.page.total"
          :hide-on-single-page="true">
        </el-pagination> -->
      </div>
    </div>

  </div>
</template>
<script>
import MemberTitle from '@/components/MemberTitle';
import SldCommonEmpty from '@/components/SldCommonEmpty';
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'giftPoint',
  components: {
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const router = useRouter()
    const noneUrl = require('@/assets/common_empty.png');
    const pointList = reactive({ list: [] })
    const pageData = reactive({ page: {} })
    const indexNum = ref(0)
    const params = reactive({
      current: 1,
      pageSize: 20,
      state: 1
    })
    const consumptionRecordsShow = ref(false) //记录弹窗是否显示
    const canUseGoodShow = ref(false) //商品弹窗

    const handleSwitch = (index) => {
      indexNum.value = index
      params.state = index+1
      params.current = 1
      get_list()
    }
    const handlePrevCilickChange = (e) => {
      params.current = e
    }

    const handleNextCilickChange = (e) => {
      params.current = e
    }

    const handleCurrentChange = (e) => {
      params.current = e
    }

    const handleShowConsumptionRecords = () => {
      consumptionRecordsShow.value = true
    }

    const handleShowCanUseGoods = () => {
      canUseGoodShow.value = true
    }

    // 去绑定提货券
    const toBindGiftCard = () => {
      router.push('/member/deliveryVoucher/bindDeliveryVoucher')
    }

    // 去使用
    const toUse = (val) => {
      router.push({
        path: '/buy/confirmRedemption',
        query: {
            cartId:val.cartId,
            pickId:val.pickId
        }

      })
    }
    const get_list = () => {
      proxy.$get('/v3/promotion/front/pick/list', params).then(res => {
        if (res.state == 200) {
          pointList.list = res.data
        }
      })
    }

    onMounted(() => {
        get_list()
    })

    return {
      L,
      pointList,
      pageData,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      handleSwitch,
      indexNum,
      consumptionRecordsShow,
      canUseGoodShow,
      handleShowConsumptionRecords,
      handleShowCanUseGoods,
      toBindGiftCard,
      toUse,
      get_list,
      noneUrl,
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/member/deliveryVoucher.scss';

.sld_pagination {
  margin-right: 40px;
  margin-bottom: 20px;
}
.detail_none {
        flex-direction: column;
        margin-top: 7px;
        width: inherit;
        height: 200px;
    }

    .detail_none p {
        padding: 7px;
    }
</style>